<template>
    <div class="newsBox">
        <!-- banner -->
        <div class="banner"></div>
        <!-- 内容 -->
        <div class="content">
            <div class="container">
                <!-- 面包屑和搜索 -->
                <div class="top">
                    <div class="crumbs">
                        <i>
                            <img src="../assets/img/loginIcon/index-icon.png" alt="">
                        </i>
                        <a href="javascript:;">首页</a>
                        <p>></p>
                        <a href="javascript:;" class="clickActive">资讯中心</a>
                    </div>
                     <div class="searchInput">
                        <el-input
                        placeholder="请输入您感兴趣的课程"
                        clearable
                        v-model="inputValue"
                        @keyup.enter.native="toSearch"
                        >
                            <el-button class="searchBtn"
                                        size="mini"
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="toSearch"
                            ></el-button>
                        </el-input>
                    </div>
                </div>
                <div class="newsList">
                    <div class="newsListBox">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane v-for="(item,index) in categoryList" :key="index" :label="item.name" :name="item.name">
                                <div class="newsListContent">
                                    <div v-if="newsList.length == 0" style="text-align: center;font-size: 28px;color:#FF5A74;">
                                        暂无资讯
                                    </div>
                                    <div v-else>
                                        <ul>
                                            <li v-for="(item,index) in newsList" :key="index">
                                                <a href="javascript:;" @click="learnNow(item.id,categoryId)">
                                                    <div class="Img">
                                                        <img :src="item.logoUrl" alt="">
                                                    </div>
                                                    <div class="text">
                                                        <p class="topTitle">{{item.title}}</p>
                                                        <p class="subTitle">{{item.description}}</p>
                                                        <p class="time">{{item.publishDate}}</p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <el-pagination
                                        background
                                        layout="prev, pager, next"
                                        prev-text="上一页"
                                        next-text="下一页"
                                        :page-sizes="[2, 5, 10, 20]"
                                        :current-page="pageNumber"
                                        :total="total"
                                        :page-size="pageSize"
                                        @size-change="sizeChangeHandle"
                                        @current-change="currentChangeHandle">
                                        </el-pagination>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="newsHot">
                        <div class="titleBox">
                            <p>热门推荐</p>
                        </div>
                        <div class="slideContent">
                            <div v-if="this.hotList.length == 0">
                                暂无数据
                            </div>
                            <div v-else>
                                <ul>
                                    <li v-for="(item,index) in hotList" :key="index">
                                        <a href="javascript:;" @click="learnNow(item.id,type)">{{item.title}}
                                            </a>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                        <div class="hotImg">
                            <a href="/#/Live">
                                <div class="liveCenter">
                                    <p class="title">直播中心</p>
                                    <div class="line"></div>
                                    <p class="subTitle">直播+录播双向教学</p>
                                </div>
                            </a>
                            <a href="/#/QuestionBank">
                                <div class="onlineQuestionBank">
                                    <p class="title">在线题库</p>
                                    <div class="line"></div>
                                    <p class="subTitle">历年真题自测估分</p>
                                </div>
                            </a>
                            <a href="/#/ClassList">
                                <div class="curriculumCenter">
                                    <p class="title">课程中心</p>
                                    <div class="line"></div>
                                    <p class="subTitle">零基础在线学习</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'News',
    data() {
        return {
            activeName: "全部文章",
            inputValue: '',
            type: -2,
            categoryId: '',
            hotList: [],
            categoryList: [],
            newsList: [],
            total: 0,
            pageSize: 5,
            pageNumber: 1,
        }
    },
    mounted() {
        this.getHot();
        this.getCategory();
        this.getList(-1);
    },
    metaInfo: {
        title: '资讯', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        learnNow(id,cype) {
            this.$router.push({
                name: 'newsdetail',
                params:{id,categoryId: cype}
            })
        },
        // 获取数据列表
        getList(id) {
            let that = this;
            that.axios({
                method: 'POST',
                url: 'api/v2/yun/information/page.html',
                data: {
                    categoryId: id,
                    pageNumber: that.pageNumber,
                    pageSize: that.pageSize
                }
            }).then((res) => {
                that.newsList = res.data.result.list;
                this.total = res.data.result.total;
                this.categoryId = res.data.result.categoryId;
            })
        },
        getSearch() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/page.html',
                data: {
                    categoryId: this.categoryId,
                    searchKey: this.inputValue,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                }
            }).then((res) => {
                this.newsList = res.data.result.list;
                this.total = res.data.result.total;
            })
        },
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageNumber = 1
            this.getList(this.categoryId);
        },
        currentChangeHandle(val) {
            this.pageNumber = val;
            // console.log(this.categoryId,"翻页")
            this.getList(this.categoryId);
        },
        getCategory() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/getCategory.html'
            }).then((res) => {
                this.categoryList = res.data.result.categoryList;
                let all = {
                    "id": -1,
                    "name": "全部文章"
                }
                this.categoryList.unshift(all);
            })
        },
        // 获取热门
        getHot() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/information/getCommend.html'
            }).then((res) => {
                this.hotList = res.data.result.commendList;
            })
        },
        toSearch() {
            if (this.inputValue == '') return this.$message.error("搜索关键字为空!")
            // this.$store.commit("setSearch", this.inputValue);
            this.getSearch(this.categoryId);

        },
        handleClick(tab) {
            this.getList(this.categoryList[tab.index].id)
        }
    }
}
</script>

<style lang="scss">
    .el-pagination {
        text-align: center;
    }
    .newsBox {
        margin-top: 70px;
        .banner {
            min-width: 1200px;
            width: 100%;
            height: 150px;
            background: url("../assets/img/newsBanner.jpg") no-repeat;
        }
        .content {
            width: 100%;
            min-width: 1200px;
            padding-top: 60px;
            padding-bottom: 40px;
            box-sizing: border-box;
            .container {
                margin: 0 auto;
                width: 1200px;
                .top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    .crumbs {
                        display: flex;
                        align-items: center;
                        i {
                            display: inline-block;
                            margin-right: 5px;
                            width: 14px;
                            height: 14px;
                        }
                        p {
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        a {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            display: inline-block;
                            color: #666666;
                        }
                        .clickActive {
                            color: #FF5A74;
                        }
                    }
                    .searchInput {
                        width: 431px;
                        .el-input__inner {
                            width: 343px;
                            height: 48px;
                            border: 1px solid #FF5A74;
                            border-radius: 5px 0px 0px 5px;
                        }
                        .el-input-group__append {
                            padding: 0 41px;
                            background: #FF5A74;
                            border-radius: 0px 5px 5px 0px;
                            border: 1px solid #FF5A74;
                            .el-icon-search {
                                color: #ffffff;
                            }
                        }
                    }
                }
                .newsList {
                    display: flex;
                    justify-content: space-between;
                    .newsListBox {
                        .el-tabs {
                            .el-tabs__header {
                                width: 839px;
                                height: 52px;
                                border: 1px solid #EEEEEE;
                                margin-bottom: 40px;
                                .el-tabs__nav-wrap {
                                    overflow: visible;
                                    &:after {
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        background-color: transparent;
                                    }
                                    .el-tabs__nav-scroll {
                                        overflow: visible;
                                        .el-tabs__nav {
                                            .el-tabs__active-bar {
                                                width: 127px !important;
                                                height: 3px;
                                                background: #E53B56;
                                                transition: none;
                                            }
                                            .el-tabs__item {
                                                padding: 0;
                                                &:hover {
                                                    color: #333333;
                                                }
                                            }
                                            .el-tabs__item.is-top {
                                                padding: 0;
                                            }
                                            .el-tabs__item.is-active {
                                                background: #FF5A74;
                                                color: #FFFFFF;
                                                position: relative;
                                                &::after {
                                                    border: solid transparent;
                                                    content: ' ';
                                                    height: 0;
                                                    top: 49px;
                                                    position: absolute;
                                                    width: 0;
                                                    border-width: 7px;
                                                    border-top-color: #FF5A74;
                                                    left: 57px;
                                                    z-index: 1;
                                                }
                                                &::before {
                                                    border: solid transparent;
                                                    content: ' ';
                                                    height: 0;
                                                    top: 52px;
                                                    position: absolute;
                                                    width: 0;
                                                    border-width: 7px;
                                                    border-top-color: #E53B56;
                                                    left: 57px;
                                                }
                                            }
                                            .el-tabs__item {
                                                width: 127px;
                                                height: 54px;
                                                text-align: center;
                                                line-height: 54px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .el-tabs__content {
                            overflow: visible;
                        }
                    }
                    .newsListContent {
                        ul {
                            li {
                                padding-bottom: 20px;
                                margin-bottom: 20px;
                                box-sizing: border-box;
                                border-bottom: 1px solid #EEEEEE;
                                a {
                                    display: block;
                                    display: flex;
                                    align-items: center;
                                    .Img {
                                        margin-right: 20px;
                                        width: 200px;
                                        height: 125px;
                                        // background: blue;
                                    }
                                    .text {
                                        width: 614px;
                                        margin-top: 7px;
                                        p {
                                            &:nth-child(1) {
                                                margin-bottom: 8px;
                                                font-size: 18px;
                                                color: #333333;
                                            }
                                            &:nth-child(2) {
                                                margin-bottom: 10px;
                                                font-size: 14px;
                                                line-height: 20px;
                                                color: #666666;
                                            }
                                            &:nth-child(3) {
                                                font-size: 14px;
                                                color: #999999;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .newsHot {
                        padding: 17px 19px;
                        box-sizing: border-box;
                        width: 340px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        .titleBox {
                            padding-bottom: 16px;
                            box-sizing: border-box;
                            width: 300px;
                            border-bottom: 1px solid #EEEEEE;
                            p {
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                        }
                        .slideContent {
                            margin: 0 auto;
                            margin-bottom: 40px;
                            width: 300px;
                            padding-top: 24px;
                            box-sizing: border-box;
                            ul {     
                                padding-left: 17px;
                                box-sizing: border-box;
                                li {
                                    margin-bottom: 20px;
                                    list-style: circle;
                                    a {
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                }
                            }
                        }
                        .hotImg {
                            .liveCenter {
                                margin-bottom: 10px;
                                padding: 29px 30px;
                                box-sizing: border-box;
                                width: 300px;
                                height: 110px;
                                background: url("../assets/img/news-live.jpg") no-repeat;
                                .title {
                                    margin-bottom: 5px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .line {
                                    margin-bottom: 15px;
                                    width: 18px;
                                    height: 2px;
                                    background: #FA4B66;
                                }
                                .subTitle {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #666666;
                                }
                            }
                            .onlineQuestionBank {
                                margin-bottom: 10px;
                                padding: 29px 30px;
                                box-sizing: border-box;
                                width: 300px;
                                height: 110px;
                                background: url("../assets/img/news-question.jpg") no-repeat;
                                .title {
                                    margin-bottom: 5px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .line {
                                    margin-bottom: 15px;
                                    width: 18px;
                                    height: 2px;
                                    background: #FA4B66;
                                }
                                .subTitle {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #666666;
                                }
                            }
                            .curriculumCenter {
                                padding: 29px 30px;
                                box-sizing: border-box;
                                width: 300px;
                                height: 110px;
                                background: url("../assets/img/news-class.jpg") no-repeat;
                                .title {
                                    margin-bottom: 5px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .line {
                                    margin-bottom: 15px;
                                    width: 18px;
                                    height: 2px;
                                    background: #FA4B66;
                                }
                                .subTitle {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>